import client from "src/libs/client";
import api from ".";

const path = "/admin/merchants";

const createAPI = async (_id: string, data: any) => {
    const res = await client.post(`${path}/${_id}/apis`, data);

    if (res.data?.status_code !== 0) {
        throw Error(res.data?.message);
    }

    return res.data;
};

const updateAPI = async (_id: string, apiId: string, data: any) => {
    const res = await client.patch(`${path}/${_id}/apis/${apiId}`, data);

    if (res.data?.status_code !== 0) {
        throw Error(res.data?.message);
    }

    return res.data;
};

const apiMerchants = api(path, { createAPI, updateAPI });
export default apiMerchants;
