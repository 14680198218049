import { Button, Result } from "antd";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <Result
        status="404"
        title="404"
        subTitle="Уучлаарай тухайн хуудас олдсонгүй"
        extra={
          <Link to="/">
            <Button type="primary" size="large">
              Буцах
            </Button>
          </Link>
        }
      />
    </div>
  );
};

export default NotFound;
