/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { Table, DatePicker, Form, Button, Modal, Input } from "antd";
import { useEffect, useState } from "react";
import moment from "moment";
import Title from "../components/Title";
import { useQuery } from "react-query";
import apiInvoices from "src/api/invoices";
import CheckInvoice from "../components/CheckInvoice";
import { BiDetail } from "react-icons/bi";
import { Link } from "react-router-dom";

const { RangePicker }: any = DatePicker;

const InvoicesPage = () => {
    const [popUpItem, setPopUpItem] = useState<any>();
    const [popUp, setPopUp] = useState(false);

    const [dates, setDates] = useState({
        start: moment(
            new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate() - 7
            )
        ).format("YYYY-MM-DD"),
        end: moment(new Date()).format("YYYY-MM-DD"),
        Picker: [
            moment(
                new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate() - 7
                )
            ),
            moment(new Date()),
        ],
    });

    const [userFilter, setUserFilter] = useState(null);

    const [filters, setFilters] = useState({});

    const [offset, setOffset] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    // const [form] = Form.useForm();

    const { data, isLoading, isRefetching, refetch } = useQuery(
        "invoices",
        () => {
            console.log("FETCHING");
            console.log(userFilter);
            let start = "";
            let end = "";
            const sDate = dates.start.split("-");
            const eDate = dates.end.split("-");

            if (sDate[0] !== "" && eDate[0] !== "") {
                start =
                    Math.floor(
                        new Date(
                            parseInt(sDate[0]),
                            parseInt(sDate[1]) - 1,
                            parseInt(sDate[2]),
                            0,
                            0,
                            1
                        ).valueOf() / 1000
                    ).toString(16) + "0000000000000000";
                end =
                    Math.floor(
                        new Date(
                            parseInt(eDate[0]),
                            parseInt(eDate[1]) - 1,
                            parseInt(eDate[2]),
                            23,
                            59,
                            59
                        ).valueOf() / 1000
                    ).toString(16) + "0000000000000000";
            }

            return apiInvoices.find(offset, pageSize, {
                _id: { $gte: start, $lte: end },
                customer:
                    userFilter != null && userFilter !== ""
                        ? userFilter
                        : undefined,
                ...filters,
            });
        }
    );

    const onChange = (pagination, filters, sorter) => {
        const offset =
            pagination.current * pagination.pageSize - pagination.pageSize;

        console.log("Pagination", pagination);
        // const limit = pagination.pageSize;

        // const params = {};

        // if (sorter.hasOwnProperty("column")) {
        //     params.order = { field: sorter.field, dir: sorter.order };
        // }

        setPageSize(pagination.pageSize);
        setOffset(offset);
        setFilters(filters);
    };

    useEffect(() => {
        refetch();
    }, [offset, filters]);

    const columns = [
        {
            title: "#",
            key: "index",
            render: (_: any, __: any, index: number) => {
                return `${index + 1}`;
            },
        },
        {
            title: "Төлөв",
            dataIndex: "status",
            key: "status",
            filters: [
                {
                    text: "Төлсөн",
                    value: 2,
                },
                {
                    text: "Төлөгдөөгүй",
                    value: 0,
                },
            ],
            onFilter: (value: any, record: any) => {
                console.log("VAL", value);
                return record.status === value;
            },
            render: (data) => {
                let paid = <span style={{ color: "red" }}>Төлөгдөөгүй</span>;
                if (data === 2)
                    paid = <span style={{ color: "green" }}>Төлсөн</span>;
                return paid;
            },
        },
        {
            title: "Хэрэглэгч",
            dataIndex: "customer",
            key: "customer",
            render: (data) => {
                return data.username || "-";
            },
        },
        {
            title: "Мөнгөн дүн",
            dataIndex: "amount",
            key: "amount",
            render: (data) => {
                return data || "-";
            },
        },
        {
            title: "Огноо",
            dataIndex: "_id",
            key: "date",
            render: (data) => {
                return (
                    moment(
                        new Date(parseInt(data.substring(0, 8), 16) * 1000)
                    ).format("YYYY-MM-DD HH:mm") || "-"
                );
            },
        },
        {
            title: "Билл дугаар",
            dataIndex: "billNumber",
            key: "billNumber",
            render: (data) => {
                return data ?? "-";
            },
        },
        {
            title: "Таних дугаар",
            dataIndex: "friendlyNumber",
            key: "friendlyNumber",
            render: (data) => {
                return data ?? "-";
            },
        },
        {
            title: "Үйлдэл",
            key: "action",
            width: 80,
            render: (row: any, __: any, index: number) => {
                let buttons = [];
                // if (row.status !== 2) {
                //     buttons.push(
                //         <Button
                //             key={`action-check-${row["_id"]}`}
                //             onClick={() => {
                //                 setPopUpItem({ ...row, id: index });
                //                 setPopUp(true);
                //             }}
                //         >
                //             <GiClick size={20} color="green" />
                //         </Button>
                //     );
                // }

                buttons.push(
                    <Link
                        key={`action-detail-${row["_id"]}`}
                        to={`/invoices/${row["_id"]}`}
                    >
                        <Button>
                            <BiDetail size={20} color="black" />
                        </Button>
                    </Link>
                );

                return <div className="flex space-x-2">{buttons}</div>;
            },
        },
    ];

    const onDateChange = (val, str) => {
        if (str[0] !== "")
            setDates({
                start: str[0],
                end: str[1],
                Picker: [moment(str[0]), moment(str[1])],
            });
        else setDates({ start: "", end: "", Picker: [] });
    };

    return (
        <>
            <div className="flex justify-between items-center">
                <Title title="Нэхэмжлэхийн жагсаалт" />
            </div>

            <div className="bg-white border rounded overflow-hidden p-2">
                <div className="flex">
                    <Form
                        // form={form}
                        className="flex"
                        initialValues={{ Picker: dates.Picker }}
                    >
                        <Form.Item
                            label="Огноогоор хайх"
                            style={{ marginBottom: 8, marginLeft: 10 }}
                            name="Picker"
                        >
                            <RangePicker onChange={onDateChange} />
                        </Form.Item>
                        <Form.Item
                            label="Хэрэглэгчээр хайх"
                            style={{ marginBottom: 8, marginLeft: 10 }}
                            name="User"
                        >
                            <Input
                                type="text"
                                value={userFilter}
                                allowClear={true}
                                onChange={(e) => setUserFilter(e.target.value)}
                            />
                        </Form.Item>
                        <Button
                            onClick={() => {
                                refetch();
                            }}
                        >
                            Хайх
                        </Button>
                    </Form>
                </div>
                <Table
                    rowKey="billNumber"
                    className="components-table-demo-nested"
                    onChange={onChange}
                    columns={columns}
                    dataSource={data?.data}
                    loading={isLoading || isRefetching}
                    pagination={{
                        total: data?.pagination?.total,
                    }}
                />

                <Modal
                    key={Math.random().toString()}
                    footer={false}
                    visible={popUp}
                    onCancel={() => {
                        setPopUp(false);
                        setPopUpItem(undefined);
                    }}
                >
                    <CheckInvoice
                        key={Math.random().toString()}
                        data={popUpItem}
                        id={popUpItem?._id}
                        close={() => {
                            setPopUp(false);
                            setPopUpItem(undefined);
                        }}
                    />
                </Modal>
            </div>
        </>
    );
};

export default InvoicesPage;
