import {
    Button,
    Card,
    Col,
    Form,
    Input,
    notification,
    Row,
    Typography,
} from "antd";
import { useQuery } from "react-query";
import apiMerchants from "src/api/merchants";
import Title from "src/components/Title";

const MerchantPage = () => {
    const { data, isLoading } = useQuery("merchant", () => {
        return apiMerchants.findOne("x"); // HACK: backend route is not clear.
    });

    console.log(data);

    const onFinish = async (formData) => {
        console.log("formData: ", formData);

        notification.success({
            message: "Амжилттай.",
            description: `Мэдээлэл шинэчлэгдлээ.`,
        });
    };

    return (
        <>
            <div className="flex justify-between items-center">
                <Title title="Байгууллагын мэдээлэл" />
            </div>

            {!isLoading && data && (
                <Row>
                    <Col offset={6} span={12}>
                        <Card>
                            <Typography.Title level={3}>
                                Системийн тохиргоо
                            </Typography.Title>
                            <Form
                                labelCol={{ span: 6 }}
                                wrapperCol={{ span: 18 }}
                                initialValues={{ remember: true }}
                                onFinish={onFinish}
                                autoComplete="off"
                            >
                                <Form.Item
                                    label="ID"
                                    key="_id"
                                    name="_id"
                                    initialValue={data?._id}
                                >
                                    <Input disabled={true} />
                                </Form.Item>
                                <Form.Item
                                    label="Нэр"
                                    key="name"
                                    name="name"
                                    initialValue={data?.name}
                                >
                                    <Input disabled={true} />
                                </Form.Item>
                                <Form.Item
                                    label="Redirect Url"
                                    key="redirectUrl"
                                    name="redirectUrl"
                                    initialValue={data?.redirectUrl}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Заавал бөглөнө үү.",
                                        },
                                        {
                                            type: "url",
                                            message: "Буруу хаяг байна.",
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Callback Url"
                                    key="callbackUrl"
                                    name="callbackUrl"
                                    initialValue={data?.callbackUrl}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Заавал бөглөнө үү.",
                                        },
                                        {
                                            type: "url",
                                            message: "Буруу хаяг байна.",
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Row className="justify-center">
                                    <Form.Item>
                                        <Button
                                            size="large"
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            Илгээх
                                        </Button>
                                    </Form.Item>
                                </Row>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            )}
        </>
    );
};

export default MerchantPage;
