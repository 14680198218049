import { Badge, Card } from "antd";
import Meta from "antd/lib/card/Meta";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const PaymentProcessorCard = (props) => {
    const { isActive, logoUrl, name, description, slug } = props;

    return (
        <Badge.Ribbon
            className={isActive === undefined ? "hidden" : ""}
            text={isActive ? "Идэвхитэй" : "Түр зогсоосон"}
            color={isActive ? "green" : "red"}
        >
            <Card
                cover={<img alt={name} src={logoUrl} />}
                actions={[
                    <Link to={`/settings/payment-processors/${slug}`}>
                        <EditOutlined key="edit" />
                    </Link>,
                ]}
                bodyStyle={{ height: "200px" }}
            >
                <Meta title={name} description={description} />
            </Card>
        </Badge.Ribbon>
    );
};

export default PaymentProcessorCard;
