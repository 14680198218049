import axios from "axios";
import config from "../config";

const ssoClient = axios.create({
    baseURL: config.ssoApiBaseUrl,
});

ssoClient.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem("token");
        if (token && token !== "") {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default ssoClient;
