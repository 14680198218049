import { Button, Table } from "antd";
import { useEffect, useState } from "react";
import { BiDetail } from "react-icons/bi";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import apiUsers from "src/api/users";
import Title from "src/components/Title";

const UsersPage = () => {
    const { data, isLoading, refetch, isRefetching } = useQuery(
        "merchant",
        () => {
            return apiUsers.find(offset, 10, filters); // HACK: backend route is not clear.
        }
    );

    const [filters, setFilters] = useState({});

    const [offset, setOffset] = useState(0);

    const onChange = (pagination, filters, sorter) => {
        const offset =
            pagination.current * pagination.pageSize - pagination.pageSize;

        // const limit = pagination.pageSize;

        // const params = {};

        // if (sorter.hasOwnProperty("column")) {
        //     params.order = { field: sorter.field, dir: sorter.order };
        // }

        setOffset(offset);
        setFilters(filters);
    };

    useEffect(() => {
        refetch();
    }, [offset, filters, refetch]);

    const columns = [
        {
            title: "#",
            key: "index",
            render: (_: any, __: any, index: number) => {
                return `${index + 1}`;
            },
        },
        {
            title: "Username",
            key: "username",
            dataIndex: "username",
        },
        {
            title: "Нэр",
            key: "firstname",
            dataIndex: ["metadata", "firstname"],
        },
        {
            title: "Утасны дугаар",
            key: "mobile",
            dataIndex: ["metadata", "mobile"],
        },
        {
            title: "Холбосон карт",
            key: "cards",
            dataIndex: "cards",
            render: (data) => {
                return data?.length || "0";
            },
        },
        {
            title: "Үйлдэл",
            key: "action",
            width: 80,
            render: (row: any, __: any, index: number) => {
                let buttons = [];

                buttons.push(
                    <Link
                        key={`action-detail-${row["_id"]}`}
                        to={`/users/${row["_id"]}`}
                    >
                        <Button>
                            <BiDetail size={20} color="black" />
                        </Button>
                    </Link>
                );

                return <div className="flex space-x-2">{buttons}</div>;
            },
        },
    ];

    return (
        <>
            <div className="flex justify-between items-center">
                <Title title="Хэрэглэгчдийн жагсаалт" />
            </div>

            {!isLoading && data && (
                <div className="bg-white border rounded">
                    <Table
                        onChange={onChange}
                        rowKey="_id"
                        columns={columns}
                        dataSource={data?.data}
                        loading={isLoading || isRefetching}
                        pagination={{
                            total: data?.pagination?.total,
                        }}
                    />
                </div>
            )}
        </>
    );
};

export default UsersPage;
