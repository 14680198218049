import { Button, Col, Descriptions, notification, Row } from "antd";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import apiInvoices from "src/api/invoices";
import Title from "src/components/Title";

const InvoiceDetailPage = () => {
    const params = useParams();

    const { data, refetch, isLoading } = useQuery("getInvoiceDetail", () =>
        apiInvoices.findOne(params["invoiceId"])
    );

    const recheck = async () => {
        const res = await apiInvoices.check(params["invoiceId"]);

        notification.success({
            message: "Дахин шалгалт амжилттай.",
            description: `Дахин шалгалт хийв. Үр дүн: ${JSON.stringify(
                res.data
            )}`,
        });

        refetch();
    };

    const resendCallback = async () => {
        const res = await apiInvoices.resendCallback(params["invoiceId"]);

        notification.success({
            message: "Дахин илгээв.",
            description: `Үр дүн: ${JSON.stringify(res.data)}`,
        });
    };

    return (
        <>
            <div className="flex justify-between items-center">
                <Title title="Нэхэмжлэхийн мэдээлэлэл" />
            </div>
            <Row>
                <Col className="flex flex-end space-x-2" span={32}>
                    <Button type="primary" onClick={() => recheck()}>
                        Дахин шалгах
                    </Button>
                    <Button type="primary" onClick={() => resendCallback()}>
                        Callback дахин дуудах
                    </Button>
                </Col>
            </Row>
            {!isLoading ? (
                <Row gutter={16}>
                    <Col span={12}>
                        <Descriptions
                            size="small"
                            title="Мэдээлэл"
                            bordered
                            column={1}
                        >
                            <Descriptions.Item label="ID">
                                {data["_id"] ?? ""}
                            </Descriptions.Item>
                            <Descriptions.Item label="Төлөв">
                                {data["status"] !== 2 ? (
                                    <span style={{ color: "red" }}>
                                        Төлөгдөөгүй
                                    </span>
                                ) : (
                                    <span style={{ color: "green" }}>
                                        Төлсөн
                                    </span>
                                )}
                            </Descriptions.Item>
                            <Descriptions.Item label="Friendly number">
                                {data["friendlyNumber"] ?? ""}
                            </Descriptions.Item>
                            <Descriptions.Item label="Bill number">
                                {data["billNumber"]}
                            </Descriptions.Item>
                            <Descriptions.Item label="Хэрэглэгч">
                                {data["customer"]["username"]}
                            </Descriptions.Item>
                            <Descriptions.Item label="Барааны мэдээлэл">
                                {JSON.stringify(data["products"])}
                            </Descriptions.Item>
                            <Descriptions.Item label="Үнэ">
                                Нийт: {data["amount"]} <br />
                                Төлсөн: {data["paid_amount"]}
                            </Descriptions.Item>
                            <Descriptions.Item label="Тайлбар">
                                {data["description"]}
                            </Descriptions.Item>
                            <Descriptions.Item label="Fail Redirect URL">
                                {data["failRedirectUrl"]}
                            </Descriptions.Item>
                            <Descriptions.Item label="Success Redirect URL">
                                {data["successRedirectUrl"]}
                            </Descriptions.Item>
                            <Descriptions.Item label="Callback URL">
                                {data["merchant"]["callbackUrl"]}
                            </Descriptions.Item>
                            <Descriptions.Item label="Үүсгэсэн огноо">
                                {data["createdAt"]}
                            </Descriptions.Item>
                            <Descriptions.Item label="Сүүлд шинэчилсэн огноо">
                                {data["updatedAt"]}
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                    <Col span={12}>
                        <Descriptions title="Үйлдлийн лог" bordered column={1}>
                            {data["logs"].map((el: any) => {
                                return (
                                    <Descriptions.Item
                                        key={el._id}
                                        label={
                                            <span>
                                                <strong>{el["type"]}</strong>
                                                <br />
                                                {el["createdAt"]}
                                            </span>
                                        }
                                    >
                                        <div
                                            style={{
                                                maxHeight: "200px",
                                                overflowY: "scroll",
                                            }}
                                        >
                                            {el["data"]}
                                        </div>
                                    </Descriptions.Item>
                                );
                            })}
                        </Descriptions>
                    </Col>
                </Row>
            ) : null}
        </>
    );
};

export default InvoiceDetailPage;
