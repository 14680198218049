import { createContext, useEffect, useState } from "react";
import apiAuth from "src/api/auth";

const AuthContext = createContext();

export const AuthProvider = (props) => {
    const [user, setUser] = useState(null);
    const [loaded, setLoaded] = useState(false);

    const login = async (user) => {
        const res = await apiAuth.signIn(user.username, user.password);
        if (res.success) {
            console.log("xx", res?.data);
            const { accessToken } = res?.data;

            if (accessToken && accessToken !== "") {
                localStorage.setItem("token", accessToken);
            }
            setUser(user);
            setUserData(user);
        }
        return res;
    };

    const logout = () => {
        setUser(null);
        setUserData(null);
    };

    const setUserData = (user) => {
        if (user) {
            localStorage.setItem("user", JSON.stringify(user));
        } else {
            localStorage.removeItem("user");
        }
    };

    const getUserData = () => {
        const tmp = localStorage.getItem("user");
        return tmp ? JSON.parse(tmp) : null;
    };

    useEffect(() => {
        setUser(getUserData());
        setLoaded(true);
    }, []);

    return (
        <AuthContext.Provider value={{ user, login, logout, loaded }}>
            {props.children}
        </AuthContext.Provider>
    );
};

export const AuthConsumer = AuthContext.Consumer;

export default AuthContext;
