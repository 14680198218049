const { REACT_APP_DEV_HOST } = process.env;
const { REACT_APP_API_BASE_URL } = process.env;
const { REACT_APP_SSO_API_BASE_URL } = process.env;

const config = {
    host: REACT_APP_DEV_HOST,
    apiBaseUrl: REACT_APP_API_BASE_URL,
    ssoApiBaseUrl: REACT_APP_SSO_API_BASE_URL,
};

export default config;
