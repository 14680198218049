import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from "react-router-dom";

import { QueryClient, QueryClientProvider } from "react-query";

import { AuthConsumer, AuthProvider } from "./contexts/AuthContext";
import useAuth from "./hooks/useAuth";
import routing from "./routing";
import DefaultLayout from "./components/Layout";
import NotFound from "./pages/NotFound";

const queryClient = new QueryClient();

const PrivateRoute = ({
    component: Component,
    layout: Layout,
    ...rest
}: any) => {
    const { user } = useAuth();
    return (
        <Route
            {...rest}
            render={(props) =>
                user ? (
                    Layout ? (
                        <Layout>
                            <Component />
                        </Layout>
                    ) : rest?.noLayout ? (
                        <Component />
                    ) : (
                        <DefaultLayout>
                            <Component {...props} />
                        </DefaultLayout>
                    )
                ) : (
                    <Redirect to={"/signin"} />
                )
            }
        />
    );
};

const PublicRoute = ({ component: Component, ...rest }: any) => {
    const { user } = useAuth();
    return (
        <Route
            {...rest}
            render={(props) =>
                !user ? <Component {...props} /> : <Redirect to={"/"} />
            }
        />
    );
};

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <AuthProvider>
                <Router>
                    <AuthConsumer>
                        {({ loaded }) =>
                            loaded && (
                                <Switch>
                                    {routing?.map((route) =>
                                        route?.public ? (
                                            <PublicRoute key="s" {...route} />
                                        ) : (
                                            <PrivateRoute key="p" {...route} />
                                        )
                                    )}
                                    <Route path={"/404"} component={NotFound} />
                                    <Redirect to={"/404"} />
                                </Switch>
                            )
                        }
                    </AuthConsumer>
                </Router>
            </AuthProvider>
        </QueryClientProvider>
    );
}

export default App;
