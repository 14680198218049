/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import { Layout, Menu } from "antd";
import { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { BiLogOut, BiUser } from "react-icons/bi";
import menu from "../routing-menu";
import useAuth from "../hooks/useAuth";

const { Content, Footer, Sider } = Layout;

const DefaultLayout = (props: any) => {
    const { logout, user } = useAuth();
    const [collapsed, setCollapsed] = useState(false);
    const onCollapse = (collapsed: any) => {
        setCollapsed(collapsed);
    };

    const match = useRouteMatch();
    return (
        <Layout style={{ minHeight: "100vh" }}>
            <Sider
                className="print:hidden"
                collapsible
                collapsed={collapsed}
                onCollapse={onCollapse}
            >
                <div className="logo">
                    <img
                        src="/logo.png"
                        className={`${
                            collapsed ? "w-16" : "w-28"
                        } transition-all`}
                    />
                </div>

                <Menu
                    theme="dark"
                    defaultSelectedKeys={[match.path]}
                    mode="inline"
                    items={menu}
                />
                <div className="h-1 w-full border-t border-white/20"></div>

                <div className="flex flex-col items-center space-y-2 p-5">
                    <div className="w-10 h-10 bg-white/80 rounded-full">
                        <BiUser size={25} className="w-10 h-8" />
                    </div>
                    <span className="flex-1 text-white text-sm">
                        {user?.username}
                    </span>
                    <a
                        onClick={logout}
                        className={
                            "flex items-center text-red-500 hover:text-red-700"
                        }
                    >
                        <BiLogOut size={20} className="inline-block mr-2" />
                        Гарах
                    </a>
                </div>
            </Sider>
            <Layout className="site-layout">
                <Content style={{ margin: "0 16px" }}>{props.children}</Content>
                <Footer
                    style={{ textAlign: "center" }}
                    className="print:hidden"
                >
                    Steppe Link LLC ©{new Date().getFullYear()}.
                </Footer>
            </Layout>
        </Layout>
    );
};

export default DefaultLayout;
