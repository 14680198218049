import { Button, DatePicker, Form, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { BiDetail } from "react-icons/bi";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import apiBankTransactions from "src/api/bankTransactions";
import Title from "src/components/Title";

const { RangePicker }: any = DatePicker;

const BankTransactionsPage = () => {
    const [dates, setDates] = useState({
        start: moment(
            new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate() - 7
            )
        ).format("YYYY-MM-DD"),
        end: moment(new Date()).format("YYYY-MM-DD"),
        Picker: [
            moment(
                new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate() - 7
                )
            ),
            moment(new Date()),
        ],
    });

    const [offset, setOffset] = useState(0);

    const { data, isLoading, isRefetching, refetch } = useQuery(
        "bankTransactions",
        () => {
            console.log("FETCHING");
            let start = "";
            let end = "";
            const sDate = dates.start.split("-");
            const eDate = dates.end.split("-");

            if (sDate[0] !== "" && eDate[0] !== "") {
                start =
                    Math.floor(
                        new Date(
                            parseInt(sDate[0]),
                            parseInt(sDate[1]) - 1,
                            parseInt(sDate[2]),
                            0,
                            0,
                            1
                        ).valueOf() / 1000
                    ).toString(16) + "0000000000000000";
                end =
                    Math.floor(
                        new Date(
                            parseInt(eDate[0]),
                            parseInt(eDate[1]) - 1,
                            parseInt(eDate[2]),
                            23,
                            59,
                            59
                        ).valueOf() / 1000
                    ).toString(16) + "0000000000000000";
            }

            return apiBankTransactions.find(offset, 10, {
                _id: { $gte: start, $lte: end },
            });
        }
    );

    console.log(data);
    const onDateChange = (val, str) => {
        if (str[0] !== "")
            setDates({
                start: str[0],
                end: str[1],
                Picker: [moment(str[0]), moment(str[1])],
            });
        else setDates({ start: "", end: "", Picker: [] });
    };

    useEffect(() => {
        refetch();
    }, [refetch, offset]);

    const onChange = (pagination, filters, sorter) => {
        const offset =
            pagination.current * pagination.pageSize - pagination.pageSize;

        // const limit = pagination.pageSize;

        // const params = {};

        // if (sorter.hasOwnProperty("column")) {
        //     params.order = { field: sorter.field, dir: sorter.order };
        // }

        setOffset(offset);
    };

    const columns = [
        {
            title: "#",
            key: "index",
            render: (_: any, __: any, index: number) => {
                return `${index + 1}`;
            },
        },
        {
            title: "Огноо",
            key: "transactionDate",
            dataIndex: "transactionDate",
        },
        {
            title: "Гүйлгээний дугаар",
            key: "uniqueId",
            dataIndex: "uniquiId",
        },
        {
            title: "Дүн",
            key: "amount",
            dataIndex: "amount",
        },
        {
            title: "Утга",
            key: "description",
            dataIndex: "description",
        },
        {
            title: "Данс",
            key: "account",
            dataIndex: "account",
        },
        {
            title: "Нэхэмжлэл",
            key: "invoice",
            render: (row: any, __: any, index: number) => {
                if (row["invoice"])
                    return (
                        <Link
                            key={`action-invoice-${row["invoice"]}`}
                            to={`/invoices/${row["invoice"]}`}
                        >
                            <Button>
                                <BiDetail size={20} color="black" />
                            </Button>
                        </Link>
                    );
            },
        },
    ];

    return (
        <>
            <div className="flex justify-between items-center">
                <Title title="Банкны хуулга" />
            </div>

            <div className="bg-white border rounded overflow-hidden p-2">
                <div className="flex">
                    <Form
                        // form={form}
                        className="flex"
                        initialValues={{ Picker: dates.Picker }}
                    >
                        <Form.Item
                            label="Огноогоор хайх"
                            style={{ marginBottom: 8, marginLeft: 10 }}
                            name="Picker"
                        >
                            <RangePicker onChange={onDateChange} />
                        </Form.Item>

                        <Button
                            onClick={() => {
                                refetch();
                            }}
                        >
                            Хайх
                        </Button>
                    </Form>
                </div>
                <Table
                    rowKey="_id"
                    onChange={onChange}
                    columns={columns}
                    dataSource={data?.data}
                    loading={isLoading || isRefetching}
                    pagination={{
                        total: data?.pagination?.total,
                    }}
                />
            </div>
        </>
    );
};

export default BankTransactionsPage;
