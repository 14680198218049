import {
    Button,
    Card,
    Col,
    Form,
    Input,
    notification,
    Row,
    Switch,
    Typography,
} from "antd";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import apiPaymentProcessors from "src/api/paymentProcessors";
import Title from "src/components/Title";

const PaymentProcessorsDetailPage = () => {
    const params = useParams();

    const { data, isLoading } = useQuery("payment-processors", () => {
        return apiPaymentProcessors.findOne(params["slug"]);
    });

    const onFinish = async (formData) => {
        const tmp = {
            isActive: formData.isActive,
            credentials: formData,
        };

        const result = await apiPaymentProcessors.put(data?.setting?.slug, tmp);
        console.log("onFinish", result);

        notification.success({
            message: "Амжилттай.",
            description: `Мэдээлэл шинэчлэгдлээ.`,
        });
    };

    return (
        <>
            <div className="flex justify-between items-center">
                <Title title={`Төлбөрийн сонголт : ${data?.setting?.name}`} />
            </div>

            {!isLoading && data && (
                <Row>
                    <Col offset={6} span={12}>
                        <Card>
                            <Typography.Title level={3}>
                                Холболтын тохиргоо
                            </Typography.Title>
                            <Form
                                key={
                                    data.paymentProcessor
                                        ? "key-1"
                                        : "payment-processor-detail"
                                }
                                labelCol={{ span: 6 }}
                                wrapperCol={{ span: 18 }}
                                initialValues={{ remember: true }}
                                onFinish={onFinish}
                                autoComplete="off"
                            >
                                {data?.setting?.credentials.map((el) => {
                                    if (el.type === "string") {
                                        return (
                                            <Form.Item
                                                key={el.name}
                                                label={el.name}
                                                name={el.name}
                                                initialValue={
                                                    data?.paymentProcessor
                                                        ?.credentials?.[el.name]
                                                }
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "Заавал бөглөнө үү.",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder={el.description}
                                                />
                                            </Form.Item>
                                        );
                                    }

                                    return null;
                                })}
                                <Form.Item
                                    name="isActive"
                                    valuePropName="checked"
                                    label="Идэвхитэй"
                                    initialValue={
                                        data?.paymentProcessor?.isActive
                                    }
                                >
                                    <Switch
                                    // defaultChecked={
                                    //     data?.paymentProcessor?.isActive
                                    // }
                                    // onChange={() => {
                                    //     // setIsActive(!isActive);
                                    // }}
                                    />
                                </Form.Item>
                                <Row className="justify-center">
                                    <Form.Item>
                                        <Button
                                            size="large"
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            Илгээх
                                        </Button>
                                    </Form.Item>
                                </Row>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            )}
        </>
    );
};

export default PaymentProcessorsDetailPage;
