import client from "src/libs/client";
export type APIType = {
    find: (offset?: number, limit?: number, params?: any) => Promise<any>;
    findOne: (id: string) => Promise<any>;
    update: (object: { id: string; data: any }) => Promise<any>;
    [key: string]: (...params: any) => Promise<any>;
};

const api = (path: string, services = {}): APIType => ({
    find: async (offset: number = 0, limit: number = 10, params: any = {}) => {
        const p2 = path + `?limit=${limit}&offset=${offset}`;
        const res = await client.get(
            p2,
            Object.keys(params).length === 0 ? {} : { params }
        );

        if (res.data?.status_code !== 0) {
            throw Error(res.data?.message);
        }

        return res.data;
    },
    findOne: async (id: string) => {
        const res = await client.get(`${path}/${id}`);
        if (res.data?.status_code !== 0) {
            throw Error(res.data?.message);
        }
        return res.data?.data;
    },
    update: async (data: any) => {
        const { id, ...tmp } = data;
        const res = await client.patch(`${path}/${id}`, tmp);
        if (res.data?.status_code !== 0) {
            throw Error(res.data?.message);
        }
        return res.data?.data;
    },
    put: async (id: string, data: any) => {
        const res = await client.put(`${path}/${id}`, data);
        if (res.data?.status_code !== 0) {
            throw Error(res.data?.message);
        }
        return res.data?.data;
    },

    ...services,
});

export default api;
