import { useQuery } from "react-query";
import apiPaymentProcessors from "src/api/paymentProcessors";
import PaymentProcessorCard from "src/components/PaymentProcessorCard.tsx";
import Title from "src/components/Title";

const PaymentProcessorsPage = () => {
    const { data } = useQuery("payment-processors", () => {
        return apiPaymentProcessors.find();
    });

    return (
        <>
            <div className="flex justify-between items-center">
                <Title title="Төлбөрийн сонголтууд" />
            </div>

            <div className="grid grid-cols-6 gap-4">
                {data?.data?.map((el: any) => (
                    <PaymentProcessorCard
                        key={el.slug}
                        slug={el.slug}
                        isActive={el.isActive}
                        logoUrl={el.logoUrl}
                        name={el.name}
                        description={el.description}
                    />
                ))}
            </div>
        </>
    );
};

export default PaymentProcessorsPage;
