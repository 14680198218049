import ssoClient from "src/libs/ssoClient";

const apiAuth = {
    signIn: async (username: string, password: string) => {
        try {
            const res: any = await ssoClient.post("/client/login", {
                username,
                password,
            });

            console.log(res);
            if (res.data?.status_code !== 0) {
                return { success: false, e: res.data?.message };
            }

            return { success: true, data: res?.data?.data };
        } catch (e) {
            return { success: false, e };
        }
    },
};

export default apiAuth;
