import { Form, Input, Button } from "antd";
import { useEffect, useState } from "react";
import { FiSend } from "react-icons/fi";
import moment from "moment";

const CheckInvoice = (props: any) => {
    let { data } = props;
    const [loading, setState] = useState<any>({
        loadings: false,
        hide: "inline-block",
    });
    const [form] = Form.useForm();
    useEffect(() => {
        setTimeout(() => {
            setState({ loadings: false, hide: "inline-block" });
        }, 6000);
    }, [loading]);

    const date = moment(
        new Date(parseInt(data?._id.substring(0, 8), 16) * 1000)
    ).format("YYYY-MM-DD HH:mm");
    let paid = "Төлөгдөөгүй";
    if (data.status === 2) paid = "Төлсөн";
    data.date = date;
    data.paid = paid;

    form.setFieldsValue({ ...data });

    const enterLoading = () => setState({ loadings: true, hide: "none" });

    return (
        <>
            <Form
                key={Math.random().toString()}
                form={form}
                name="basic"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                autoComplete="off"
            >
                <Form.Item label="Мөнгөн дүн" name="amount">
                    <Input disabled={true} />
                </Form.Item>
                <Form.Item label="Огноо" name="date">
                    <Input disabled={true} />
                </Form.Item>
                <Form.Item label="Төлөгдсөн эсэх" name="paid">
                    <Input disabled={true} />
                </Form.Item>

                <div className="flex justify-center">
                    <Form.Item wrapperCol={{ span: 24 }}>
                        <Button
                            key={Math.random.toString()}
                            size="large"
                            type="primary"
                            htmlType="submit"
                            loading={loading.loadings}
                            onClick={() => enterLoading()}
                        >
                            <FiSend
                                className="mr-2"
                                style={{ display: loading.hide }}
                            />
                            Шалгах
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </>
    );
};

export default CheckInvoice;
