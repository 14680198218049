import React from "react";
import { useHistory } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
interface IProps {
  title: string;
  back?: boolean;
  suffix?: React.ReactChild;
}

const Title = ({ title, back, suffix }: IProps) => {
  let history = useHistory();
  return (
    <div className="w-full">
      <section className="flex items-center justify-between space-x-4 my-5 w-full">
        <div className="flex items-center space-x-4">
          {back && (
            <FiArrowLeft
              className="cursor-pointer text-2xl"
              onClick={() => history.goBack()}
            />
          )}{" "}
          <span className="text-xl font-bold">{title}</span>
        </div>
        <div>{suffix}</div>
      </section>
      <div className="w-full h-1 border-b mb-5 border-black border-opacity-10" />
    </div>
  );
};

export default Title;
