import client from "src/libs/client";
import api from ".";

const path = "/admin/invoices";

const check = async (invoiceId: string) => {
    const res = await client.post(`${path}/${invoiceId}/check`);

    if (res.data?.status_code !== 0) {
        throw Error(res.data?.message);
    }

    return res.data;
};

const resendCallback = async (invoiceId: string) => {
    const res = await client.post(`${path}/${invoiceId}/callback`);

    if (res.data?.status_code !== 0) {
        throw Error(res.data?.message);
    }

    return res.data;
};
const apiInvoices = api(path, { check, resendCallback });
export default apiInvoices;
