import {
    Alert,
    Button,
    Form,
    Input,
    Modal,
    notification,
    Result,
    Row,
    Table,
} from "antd";
import { useState } from "react";
import { useQuery } from "react-query";
import apiMerchants from "src/api/merchants";
import Title from "src/components/Title";
import { CopyOutlined } from "@ant-design/icons";
const APIListPage = () => {
    const { data, isLoading, refetch, isRefetching } = useQuery(
        "merchant",
        () => {
            return apiMerchants.findOne("x"); // HACK: backend route is not clear.
        }
    );

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isCreateSucceed, setIsCreateSucceed] = useState(false);
    const [createdAPIKey, setCreatedAPIKey] = useState("");

    const toggleStatus = async (_id, status) => {
        await apiMerchants.updateAPI("x", _id, {
            isActive: status,
        });

        notification.success({
            message: "Амжилттай",
            description: "Төлөв солих үйлдэл хийгдлээ.",
        });

        refetch();
    };

    const onFormSubmit = async (formData) => {
        console.log("onFormSumit", formData);

        const result = await apiMerchants.createAPI("x", formData);
        console.log("result: ", result);
        setCreatedAPIKey(result.data.key);
        setIsCreateSucceed(true);

        refetch();
    };

    const columns = [
        {
            title: "#",
            key: "index",
            render: (_: any, __: any, index: number) => {
                return `${index + 1}`;
            },
        },
        {
            title: "ID",
            dataIndex: "_id",
            key: "_id",
        },
        {
            title: "Нэр",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Key",
            dataIndex: "maskedKey",
            key: "maskedKey",
        },
        {
            title: "Үүсгэсэн огноо",
            dataIndex: "createdAt",
            key: "createdAt",
        },
        {
            title: "Төлөв",
            dataIndex: "isActive",
            key: "isActive",
            render: (data) => {
                let status = <span style={{ color: "red" }}>Идэвхигүй</span>;
                if (data)
                    status = <span style={{ color: "green" }}>Идэвхитэй</span>;
                return status;
            },
        },
        {
            title: "Үйлдэл",
            key: "action",
            render: (row: any, __: any, index: number) => {
                let buttons = [];

                if (row.isActive) {
                    buttons.push(
                        <Button
                            key={`${row._id}-action-close`}
                            type="primary"
                            danger
                            onClick={() => {
                                toggleStatus(row._id, !row.isActive);
                            }}
                        >
                            Хаах
                        </Button>
                    );
                } else {
                    buttons.push(
                        <Button
                            key={`${row._id}-action-open`}
                            type="primary"
                            onClick={() => {
                                toggleStatus(row._id, !row.isActive);
                            }}
                        >
                            Нээх
                        </Button>
                    );
                }

                return <div className="flex space-x-2">{buttons}</div>;
            },
        },
    ];

    return (
        <>
            <div className="flex justify-between items-center">
                <Title title="API жагсаалт" />
            </div>

            <div className="bg-white border rounded p-2 mb-2">
                <Button
                    type="primary"
                    onClick={() => {
                        setIsModalVisible(true);
                    }}
                >
                    Шинээр үүсгэх
                </Button>
            </div>
            <div className="bg-white border rounded">
                <Table
                    rowKey="_id"
                    columns={columns}
                    dataSource={data?.apiKeys}
                    loading={isLoading || isRefetching}
                    pagination={false}
                />
            </div>

            <Modal
                title="API үүсгэх"
                okText="Илгээх"
                footer={
                    isCreateSucceed
                        ? [
                              <Button
                                  key="close-button-1"
                                  type="primary"
                                  onClick={() => setIsModalVisible(false)}
                              >
                                  Хаах
                              </Button>,
                          ]
                        : [
                              <Button
                                  type="primary"
                                  form="createForm"
                                  key="submit-button-1"
                                  htmlType="submit"
                              >
                                  Илгээх
                              </Button>,
                          ]
                }
                visible={isModalVisible}
                onCancel={() => {
                    setIsModalVisible(false);
                }}
            >
                {!isCreateSucceed && (
                    <Form
                        layout="vertical"
                        name="createForm"
                        autoComplete="off"
                        onFinish={onFormSubmit}
                    >
                        <Form.Item
                            name="name"
                            label="Нэр"
                            key="name"
                            rules={[
                                {
                                    required: true,
                                    message: "Заавал бөглөнө үү.",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Form>
                )}
                {isCreateSucceed && (
                    <Result
                        status={"success"}
                        title="API амжилттай үүслээ."
                        extra={
                            <Row>
                                <Alert
                                    style={{ marginBottom: "0.5rem" }}
                                    message="Уг key-г дахин харах боломжгүй тул найдвартай
                                хадгалж авна уу."
                                    type="warning"
                                />
                                <Alert
                                    message={`API түлхүүр: ${createdAPIKey}`}
                                    type="error"
                                    action={
                                        <Button
                                            size="small"
                                            type="text"
                                            onClick={() => {
                                                navigator.clipboard.writeText(
                                                    createdAPIKey
                                                );

                                                notification.info({
                                                    message: "Хуулж авлаа.",
                                                    description:
                                                        "Clipboard-д API түлхүүрийг хуулсан.",
                                                });
                                            }}
                                        >
                                            <CopyOutlined />
                                        </Button>
                                    }
                                />
                            </Row>
                        }
                    />
                )}
            </Modal>
        </>
    );
};

export default APIListPage;
