import axios from "axios";
import config from "../config";

const client = axios.create({
    baseURL: config.apiBaseUrl,
});

client.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem("token");

        if (token && token !== "") {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default client;
