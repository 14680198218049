/* eslint-disable jsx-a11y/alt-text */
import { Form, Input, Button, Card, Row, Col, message } from "antd";
import { useState } from "react";
import useAuth from "../hooks/useAuth";

const  SignIn = () => {
  const [loading, setLoading] = useState(false);
  const { login } = useAuth();
  const onFinish = async (values: any) => {
    setLoading(true);
    const { username, password } = values;
    const res = await login({ username, password });

    if (res.success) {
      message.success("Тавтай морил !" + values.username);
    } else {
      message.error("Таны нэвтрэх нэр эсвэл нууц үг буруу байна !");
    }
    setLoading(false);
    return res;
  };

  return (
    <div className="relative">
      <img
        src="./steppe-payment-dashboard.webp"
        className="absolute -z-1 top-0 left-0  w-full h-full hidden md:block"
      />
      <div className=" items-center md:flex justify-center px-10 opacity-80  bg-[#102f5d] h-screen relative">
        <img
          src="/logo.png"
          className=" md:w-96 w-60 mx-auto md:mx-0 pt-10 md:pt-0"
        />
        <div className="py-40 md:w-1/2 ">
          <Row justify="end">
            <Col md={16} lg={12}>
              <Card title="Нэвтрэх хэсэг">
                <Form
                  name="basic"
                  initialValues={{}}
                  onFinish={onFinish}
                >
                  <Form.Item
                    // label="Хэрэглэгчийн нэр"
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Нэвтрэх нэрээ оруулна уу !",
                      },
                    ]}
                  >
                    <Input placeholder="Хэрэглэгчийн нэр" size="large" />
                  </Form.Item>

                  <Form.Item
                    // label="Нууц үг"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Нэвтрэх нууц үгээ оруулна уу!",
                      },
                    ]}
                  >
                    <Input.Password placeholder="Нууц үг" size="large" />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      loading={loading}
                      type="primary"
                      htmlType="submit"
                      size="large"
                    >
                      Нэвтрэх
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
